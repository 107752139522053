<template>
  <div class="BalanceDialog">
    <!-- 余额充值 -->
    <el-dialog title="余额充值" :visible.sync="balanceVisible" width="500px">
      <div class="body">
        <div class="form-item">
          <label for="">充值账户：</label>
          <span>{{ userInfo.phone }}</span>
        </div>
        <div class="form-item">
          <label for="">账户余额：</label>
          <span>{{ balanceYuan }}元</span>
        </div>
        <div class="form-item">
          <label for="">充值金额：</label>
          <div>
            <input type="number" v-model.trim="amount" />
            <span>元</span>
          </div>
        </div>
        <div class="form-item">
          <label for="">支付方式：</label>
          <el-radio-group v-model="payChannel">
            <el-radio :label="1">支付宝</el-radio>
            <el-radio :label="2">微信</el-radio>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="balanceVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="setBalance"
          >充值</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { walletCharge } from 'services/pay'
export default {
  name: 'BalanceDialog',
  props: {
    tab: {
      type: Array,
      default: () => []
    },
    tabIndex: {
      type: String,
      default: ''
    },
    balanceYuan: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      balanceVisible: false,
      amount: 0,
      payChannel: 1,
      userInfo: {}
    };
  },
  watch: {
    balanceVisible (val) {
      if (!val) {
        this.amount = 0;
        this.payChannel = 1
      }
    }
  },
  mounted () {
    // 获取用户信息
    if (localStorage.userInfo)
      this.userInfo = JSON.parse(localStorage.userInfo)
  },
  methods: {
    toPush (item) {
      console.log(item.id);
      this.prompt = item.prompt
      this.$emit("switchTab", item.id)
    },

    // 余额充值
    setBalance () {
      let str = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
      if (!this.amount) {
        return this.$message.warning('充值金额不能为0')
      } else if (!str.test(this.amount)) {
        return this.$message.warning('请确认输入的内容是否为正数')
      } else {
        let params = {
          amount: this.amount,
          payChannel: this.payChannel,
          payMethod: 3
        }
        walletCharge(params).then(res => {
          if (res.data.status === 200) {
            this.balanceVisible = false
            if (this.payChannel === 1) {
              const win = document.open('', '', 'width=1200,height=845')
              win.document.write(res.data.data.responseBody)
            } else {
              // 支付方式为微信
              let routeUrl = this.$router.resolve({
                path: '/weiXinPay'
              })
              window.open(routeUrl.href + '?codeUrl=' + res.data.data.codeUrl + '&payId=' + res.data.data.payId + '&payType=1&amount=' + this.amount, '_blank');
            }
          }
        })
      }


    },
  }
};
  </script>
  <style lang="less" scoped>
.BalanceDialog {
  /deep/ .el-dialog__header {
    text-align: center;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    .el-dialog__headerbtn {
      font-size: 20px;
    }
  }
  /deep/ .el-dialog__body {
    padding: 20px 40px;
  }
  .body {
    .form-item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(153, 153, 153, 1);
      }
      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(51, 51, 51, 1);
      }
      input {
        width: 130px;
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(252, 244, 239, 1);
        text-align: center;
      }
      /deep/ .el-radio__label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(51, 51, 51, 1);
      }
      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border: 3px solid rgba(236, 102, 0, 0.3);
        background: aliceblue;
        &::after {
          width: 8px;
          height: 8px;
          background-color: rgba(236, 102, 0, 1);
        }
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .btn {
      background: rgba(236, 102, 0, 1);
      border: 0;
    }
    /deep/ .el-button {
      height: 36px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
  