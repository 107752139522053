import axios from '../plugins/pay';

import qs from 'qs';

// form 表单传参
const form = {
  transformRequest: [
    function (data) {
      data = qs.stringify(data);
      return data;
    }
  ],
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};
// 获取认证状态
const certificationStatus = () => axios.get('/certification/status');

// 提交业主认证
const ownerSubmit = async data =>
  axios.post('/certification/owner/submit', data, form);

// 按月查询龙币总量
const coinBillTotal = params => axios.get('/coin/bill/total', { params });

// 分页查询龙币账单
const coinBillPage = params => axios.get('/coin/bill/page', { params });

// 查询龙币钱包
const coinWallet = params => axios.get('/coin/wallet', { params });

// 获取龙币价格方案
const coinPrice = params => axios.get('/coin/price', { params });

// 龙币充值
const coinCharge = data => axios.post('/coin/charge', data, form);

// 按月查询各类型账单总和
const billTotal = params =>
  axios.get('/wallet/bill/sum/type/month', { params });

// 按月查询账单明细
const billPage = params => axios.get('/wallet/bill/month', { params });

// 查询钱包余额
const billBalance = params => axios.get('/wallet/get', { params });

// 充值
const walletCharge = data => axios.post('/wallet/charge', data, form);

// 提现
// 支付账户-添加
const addPayAccount = data => axios.post('/pay/account/add', data, form);

// 支付账户-编辑
const editPayAccount = data => axios.post('/pay/account/edit', data, form);

// 支付账户-删除
const delPayAccount = data => axios.post('/pay/account/del', data, form);

// 支付账户-查询
const getPayAccount = data => axios.post('/pay/account/get', data, form);

// 支付账户-查询所有
const getPayAccountList = params => axios.get('/pay/account/all', { params });

// 提现-提交申请
const submitWalletWithdraw = data =>
  axios.post('/wallet/withdraw/submit', data, form);

// 提现-分页查询记录
const pageWalletWithdraw = params =>
  axios.get('/wallet/withdraw/page', { params });

export {
  certificationStatus,
  ownerSubmit,
  coinBillTotal,
  coinBillPage,
  coinWallet,
  billTotal,
  billPage,
  billBalance,
  walletCharge,
  coinPrice,
  coinCharge,
  addPayAccount,
  editPayAccount,
  delPayAccount,
  getPayAccount,
  getPayAccountList,
  submitWalletWithdraw,
  pageWalletWithdraw
};
