<template>
  <div class="WithdrawDepositDialog">
    <!-- 提现 -->
    <el-dialog title="提现申请" :visible.sync="balanceVisible" width="420px">
      <div class="body">
        <div class="form-item">
          <label for="">支付宝账号：</label>
          <div class="add" @click="sel">
            <span v-if="!payAccountItem">添加支付宝账号</span>
            <div v-else>
              <span class="name">{{ payAccountItem.name }}</span>
              <span class="number">{{ payAccountItem.account }}</span>
            </div>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="form-item">
          <label for="">可提现金额：</label>
          <span>{{ balanceYuan }}元</span>
          <!-- <span class="withdrawal-record" @click="toPush('/withdrawalRecord')"
            >提现记录</span
          > -->
        </div>
        <div class="form-item">
          <label class="required" for="">提现金额：</label>
          <div>
            <el-input
              class="inp"
              type="number"
              placeholder="限制0.1-10000"
              v-model="amount"
              @change="setAmount"
            >
              <span style="color: #999" slot="suffix">元</span>
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="balanceVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="setBalance"
          >提现</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="选择提现账号" :visible.sync="selectVisible" width="500px">
      <div class="body">
        <div class="form-title">
          <label style="color: rgb(51, 51, 51)" for="">支付宝账号</label>
          <div class="add" @click="add">
            <span>添加支付宝账号</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="list">
          <div
            class="item"
            :class="payAccountId === item.id ? 'active' : ''"
            v-for="item in list"
            :key="item.id"
            @click="check(item.id)"
          >
            <div
              class="radio"
              :class="payAccountId === item.id ? 'active' : ''"
            ></div>
            <span class="name">{{ item.name }}</span>
            <span class="number">{{ item.account }}</span>
            <div class="icon-box">
              <i
                class="edit icon icon-edit"
                style="margin-left: 20px"
                @click.stop="edit(item)"
              ></i>
              <i
                style="margin-left: 20px"
                class="del icon icon-del"
                @click.stop="del(item)"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="selNumber"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="填写支付宝信息" :visible.sync="addVisible" width="500px">
      <div class="body">
        <div class="form-item">
          <label class="required" for="">支付宝账号：</label>
          <div>
            <el-input
              class="inp-info"
              placeholder="请输入支付宝账号"
              v-model="addForm.account"
            >
            </el-input>
          </div>
        </div>
        <div class="form-item">
          <label class="required" for="">真实姓名：</label>
          <div>
            <el-input
              class="inp-info"
              placeholder="请输入真实姓名"
              v-model="addForm.name"
            >
            </el-input>
          </div>
        </div>
        <div class="form-item">
          <label class="required" for="">身份证号：</label>
          <div>
            <el-input
              class="inp-info"
              placeholder="请输入身份证号"
              v-model="addForm.idCard"
            >
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="addNumber"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="编辑支付宝信息" :visible.sync="editVisible" width="500px">
      <div class="body">
        <div class="form-item">
          <label class="required" for="">支付宝账号：</label>
          <div>
            <el-input
              class="inp-info"
              placeholder="请输入支付宝账号"
              v-model="editForm.account"
            >
            </el-input>
          </div>
        </div>
        <div class="form-item">
          <label class="required" for="">真实姓名：</label>
          <div>
            <el-input
              class="inp-info"
              placeholder="请输入真实姓名"
              v-model="editForm.name"
            >
            </el-input>
          </div>
        </div>
        <div class="form-item">
          <label class="required" for="">身份证号：</label>
          <div>
            <el-input
              class="inp-info"
              placeholder="请输入身份证号"
              v-model="editForm.idCard"
            >
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="editNumber"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="删除支付宝信息" :visible.sync="delVisible" width="500px">
      <div class="body">请确认是否删除该账号？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">取消</el-button>
        <el-button class="btn" type="primary" @click="delNumber"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { getPayAccountList, addPayAccount, editPayAccount, delPayAccount, submitWalletWithdraw } from 'services/pay'
export default {
  name: 'WithdrawDepositDialog',
  props: {
    balanceYuan: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      balanceVisible: false,
      selectVisible: false,
      addVisible: false,
      editVisible: false,
      delVisible: false,
      amount: 0,
      payAccountItem: null,
      addForm: {
        name: '',
        account: '',
        idCard: '',
        channel: 1
      },
      editForm: {
        id: '',
        name: '',
        account: '',
        idCard: '',
        channel: 1
      },
      list: [],
      payAccountId: null,
    };
  },
  watch: {
    balanceVisible (val) {
      if (!val) {
        this.payAccountItem = null;
        this.payAccountId = null;
        this.amount = 0
      }
    },
    addVisible (val) {
      if (!val) {
        this.addForm = {
          name: '',
          account: '',
          idCard: '',
          channel: 1
        }
      }
    },
    editVisible (val) {
      if (!val) {
        this.editForm = {
          name: '',
          account: '',
          idCard: '',
          channel: 1
        }
      }
    },
  },
  mounted () {
    if (localStorage.withdrawalInfo) this.payAccountItem = JSON.parse(localStorage.withdrawalInfo)
  },
  methods: {
    getList () {
      getPayAccountList().then(res => {
        if (res.data.status === 200) {
          this.list = res.data.data
        }
      })
    },
    toPush (path) {
      this.$router.push({
        path
      })
    },
    setAmount () {
      if (this.amount > this.balanceYuan) {
        this.amount = this.balanceYuan
        return this.$message.warning('提现金额不能大于余额')
      }
      if (this.amount < 0.1) {
        this.amount = 0.1
        return this.$message.warning('提现金额不能小于0.1')
      }
      if (this.amount > 10000) {
        this.amount = 10000
        return this.$message.warning('提现金额不能大于10000')
      }
    },
    // 余额提现
    setBalance () {
      if (!this.payAccountItem) {
        return this.$message.warning('请先选择提现账号')
      } else if (!this.amount) {
        return this.$message.warning('请输入提现金额')
      } else {
        let params = {
          payAccountId: this.payAccountItem.id,
          amount: this.amount * 100
        }
        submitWalletWithdraw(params).then(res => {
          if (res.data.status === 200) {
            this.balanceVisible = false
            localStorage.withdrawalInfo = JSON.stringify(this.payAccountItem)
            // this.$notification({
            //   title: '提现',
            //   message: '提现申请已提交，请等待工作人员的转账'
            // })
            this.$emit('withdrawSuccess')
            return this.$message({
              type: 'success',
              message: res.data.msg
            })
          }
        })
      }

    },
    sel () {
      this.selectVisible = true
      this.getList()
    },
    check (id) {
      this.payAccountId = id
    },
    selNumber () {
      this.payAccountItem = this.list.filter(item => item.id === this.payAccountId)[0]
      this.selectVisible = false
    },
    add () {
      this.addVisible = true
    },
    addNumber () {
      let reg = /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/
      if (!this.addForm.name) {
        return this.$message.warning('请输入真实姓名')
      } else if (!this.addForm.account) {
        return this.$message.warning('请输入收款账号')
      } else if (!reg.test(this.addForm.idCard)) {
        this.$message.warning('请正确输入身份证号码')
      } else {
        let params = {
          ...this.addForm
        }
        addPayAccount(params).then(res => {
          if (res.data.status === 200) {
            this.addVisible = false
            this.getList()
            return this.$message({
              type: 'success',
              message: res.data.msg
            })
          }
        })
      }
    },
    edit (item) {
      this.editVisible = true
      this.editForm = { ...item }
    },
    editNumber () {
      let reg = /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/
      if (!this.editForm.name) {
        return this.$message.warning('请输入真实姓名')
      } else if (!this.editForm.account) {
        return this.$message.warning('请输入收款账号')
      } else if (!reg.test(this.editForm.idCard)) {
        this.$message.warning('请正确输入身份证号码')
      } else {
        let params = {
          ...this.editForm
        }
        editPayAccount(params).then(res => {
          if (res.data.status === 200) {
            this.editVisible = false
            this.getList()
            return this.$message({
              type: 'success',
              message: res.data.msg
            })
          }
        })
      }
    },
    del (item) {
      this.delVisible = true
      this.editForm = { ...item }
    },
    delNumber () {
      let params = {
        id: this.editForm.id
      }
      delPayAccount(params).then(res => {
        if (res.data.status === 200) {
          this.delVisible = false
          this.getList()
          return this.$message({
            type: 'success',
            message: res.data.msg
          })
        }
      })
    }
  }
};
  </script>
  <style lang="less" scoped>
.WithdrawDepositDialog {
  /deep/ .el-dialog__header {
    text-align: center;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    .el-dialog__headerbtn {
      font-size: 20px;
    }
    .el-dialog__footer {
      padding: 0px 20px 20px;
    }
  }
  /deep/ .el-dialog__body {
    padding: 20px 40px;
  }
  .body {
    .form-item {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 12px;
      label {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 120px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(153, 153, 153, 1);
      }
      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(51, 51, 51, 1);
      }
      .withdrawal-record {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
        color: rgba(26, 133, 255, 1);
        cursor: pointer;
        margin-left: 8px;
      }
      .inp {
        width: 200px;
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        background: #f5f5f5;
        border: 0;
        display: flex;
        align-items: center;
        /deep/ .el-input__suffix {
          display: flex;
          align-items: center;
          padding-right: 10px;
        }
        /deep/.el-input__inner {
          background: #f5f5f5;
          font-size: 16px;
          border: 0;
        }
        /deep/.el-input__inner::placeholder {
          color: rgba(236, 102, 0, 1) !important;
        }
      }
      .inp-info {
        width: 290px;
        height: 40px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(252, 244, 239, 1);
        border: 0;
        display: flex;
        align-items: center;
        /deep/ .el-input__suffix {
          display: flex;
          align-items: center;
          padding-right: 10px;
        }
        /deep/.el-input__inner {
          background: rgba(245, 245, 245, 1);
          border: 0.6px solid rgba(245, 245, 245, 1);
        }
      }
      .add {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          margin-right: 10px;
          color: rgba(236, 102, 0, 1);
        }
        i {
          color: rgba(236, 102, 0, 1);
        }
      }
    }
    .form-title {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      label {
        font-size: 18px;
        font-weight: 400;
        line-height: 26.06px;
        color: rgba(0, 0, 0, 1);
        margin-right: 10px;
      }
    }
    .list {
      .item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 8px 20px;
        i {
          display: none;
        }
        &:hover {
          background: rgba(245, 245, 245, 1);
          i {
            display: block;
          }
        }
        &.active {
          background: rgba(245, 245, 245, 1);
        }
        .radio {
          width: 12px;
          height: 12px;
          opacity: 1;
          border-radius: 50%;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(221, 221, 221, 1);
          &.active {
            border: 4px solid rgba(236, 102, 0, 1);
          }
        }
        .name {
          width: 80px;
          margin: 0 20px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 23.17px;
          color: rgba(51, 51, 51, 1);
        }
        .number {
          // width: 240px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 23.17px;
          color: rgba(102, 102, 102, 1);
          margin-right: 30px;
        }
        .icon-box {
          display: flex;
          align-items: center;
        }
      }
    }

    .add {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20.27px;
      color: rgba(236, 102, 0, 1);
      cursor: pointer;
      span {
        margin-right: 10px;
        color: rgba(236, 102, 0, 1);
      }
      i {
        color: rgba(236, 102, 0, 1);
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .btn {
      background: rgba(236, 102, 0, 1);
      border: 0;
    }
    /deep/ .el-button {
      height: 36px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
  